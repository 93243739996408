import * as React from 'react'
import { Link } from 'gatsby'
import componentStyles from './component.module.scss'
import * as URL_ROOTS from 'tuna/utils/routes'

export const Sidebar = () => (
  <div className={componentStyles.sidebar}>
    <nav className={componentStyles.mainNav}>
      <Link
        to={URL_ROOTS.HOME_ROOT}
        className={componentStyles.homeLink}
        activeClassName={componentStyles.homeLinkActive}
       >
        <span role="img" aria-label="fish">🐟</span>
        <span role="img" aria-label="music">🎶</span>
      </Link>
      <Link
        to={URL_ROOTS.BLOG_ROOT}
        className={componentStyles.blogLink}
        activeClassName={componentStyles.blogLinkActive}
      >Blog</Link>
      <Link
        to={URL_ROOTS.REVIEWS_ROOT}
        className={componentStyles.reviewsLink}
        activeClassName={componentStyles.reviewsLinkActive}
      >Reviews</Link>
      <Link
        to={URL_ROOTS.PLAYLISTS_ROOT}
        className={componentStyles.playlistsLink}
        activeClassName={componentStyles.playlistsLinkActive}
      >Playlists</Link>
    </nav>
    <nav className={componentStyles.subNav}>
      <Link
        to={URL_ROOTS.ABOUT_ROOT}
        className={componentStyles.aboutLink}
      >About</Link>
    </nav>
  </div>
)