import * as React from 'react'
import { graphql } from 'gatsby'
import { CollectionProps, PreviewTemplateProps } from 'netlify-cms-app'
import { Page, Content, HTMLContent } from 'tuna/components/page'


export const BlogPost: React.FC<any> = ({ data }) => {
  const { post } = data

  return (
    <Page>
      <h1>{post.frontmatter.title}</h1>
      <p>{post.frontmatter.description}</p>
      <p>{post.frontmatter.date}</p>
      <HTMLContent content={post.html} />
    </Page>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
`

interface BlogPostTemplateProps extends CollectionProps {
  title: string
  description: string
  date: Date
}

export const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({
  content,
  description,
  title,
  date,
}) => {

  return (
    <Page noHead>
      <h1>{title}</h1>
      <p>{description}</p>
      <p>{date.toDateString()}</p>
      <Content content={content} />
    </Page>
  )
}

export const BlogPostPreview: React.FC<PreviewTemplateProps> = ({ entry, widgetFor }) => (
  <BlogPostTemplate
    content={widgetFor('body')}
    description={entry.getIn(['data', 'description'])}
    date={entry.getIn(['data', 'date'])}
    title={entry.getIn(['data', 'title'])}
  />
)
