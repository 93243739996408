const HOME_ROOT = '/'
const BLOG_ROOT = '/blog'
const REVIEWS_ROOT = '/reviews'
const PLAYLISTS_ROOT = '/playlists'
const ABOUT_ROOT = '/about'

const URL_ROOTS = {
  home: HOME_ROOT,
  reviews: REVIEWS_ROOT,
  blog: BLOG_ROOT,
  about: ABOUT_ROOT,
  playlists: PLAYLISTS_ROOT,
}

module.exports = {
  HOME_ROOT,
  BLOG_ROOT,
  REVIEWS_ROOT,
  ABOUT_ROOT,
  PLAYLISTS_ROOT,
  URL_ROOTS,
}