import * as React from 'react'
import * as cn from 'classnames'
import { Sidebar } from './components/sidebar'
import { SiteMetadata } from './components/site-metadata'
import componentStyles from './component.module.scss'

interface PageProps {
  flipSidebar?: boolean
  className?: string 
}

export const Page: React.FC<PageProps> = props => (
  <div className={cn(props.className, componentStyles.page, props.flipSidebar && componentStyles.flipSidebar)}>
    <SiteMetadata />
    <Sidebar />
    <div className={componentStyles.content}>
      {props.children}
    </div>
  </div>
)
